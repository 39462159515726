import styled from "styled-components";


export const Containerlocal = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    h1{
        color: rgb(0, 163, 255);
        text-align: center;
        font-size: 60px;
    }

    iframe{
        width: 100%;
        height: 500px;

        border: none;
    }


`