import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`

    *{
        margin: 0;
        padding: 0px;
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
    }

`