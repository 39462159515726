import styled from "styled-components";


export const ContainerFooter = styled.footer`

    background: rgb(0, 163, 255);
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    H1{
        font-size: 13PX;
        font-weight: 500;

        color: #ffffff;
        text-align: center;

    }

`