import React, {useState} from "react";
import Nav from "../../components/Nav";
import Top from "../../components/Top";
import Sobre from "../../components/Sobre";
import Footer from "../../components/Footer";
import MenuMobile from "../../components/MenuMobile";


import {
  Container,
} from "./styles"


function Home() {

  const [menuIsVisible, setMenuIsVisible] = useState(false)

  return (
    <>
    <MenuMobile
      menuIsVisible={menuIsVisible}
      setMenuIsVisible={setMenuIsVisible}
    />
    <Container>
      <Top />
      <Nav setMenuIsVisible={setMenuIsVisible}/>
      <Sobre />
      <Footer />
    </Container>
    </>
  )

}

export default Home
