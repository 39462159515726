import React from "react";

import { ContainerFooter } from "./styles";

function Footer(){
    return(
        <ContainerFooter>
        <h1>© COPYRIGHT 2024 NETVIP TELECOM - TODOS OS DIREITOS RESERVADOS</h1>
        <h1>CRIADO POR IVAN IZIDORO.</h1>
      </ContainerFooter>
    )
}

export default Footer